import type { Vehicle, VehicleDetails } from 'src/models';
const vehicleHasLabel = (vehicle: Vehicle, key: string) =>
  vehicle.labels.find((label) => label.key.includes(key));
export const vehicleIsPepite = (vehicle: Vehicle) =>
  vehicleHasLabel(vehicle, 'pepite');
export const vehicleIsExclusive = (vehicle: Vehicle) =>
  vehicleHasLabel(vehicle, 'exclusive');
export const vehicleIsCertified = (vehicle: Vehicle) =>
  vehicleHasLabel(vehicle, 'certifiee');

export const getFrenchPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/^0/, '+33');
};

export const getVehiclePrice = (vehicle: Vehicle) =>
  vehicle?.priceReduced && vehicle?.priceReduced > 0
    ? vehicle.priceReduced
    : vehicle.price;

export const hasHomeGarage = (vehicle: VehicleDetails) =>
  vehicle.garage &&
  vehicle.garage.homepageUrl.replace('/garage', '').replace('/', '').length > 0;
