import TagManager from 'react-gtm-module';

export const DEFAULT_IMAGE_VEHICLE = '/images/common/empty.jpeg';

export const gtmClickEvent = (
  eventAction: string,
  eventLabel: string,
  eventCategory = 'Fiche VO',
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory,
      eventAction,
      eventLabel,
    },
  });
};

export const getEquipmentIconName = (type: string) => {
  const existentType = [
    '4x4',
    'adml',
    'afil',
    'ammortisseurs-pilotes',
    'assistant-conduite',
    'ath',
    'attache-remorque',
    'bva',
    'camera',
    'carplay',
    'chauffage-stationnaire',
    'clim-auto',
    'coffre-auto',
    'cuir',
    'gps',
    'jantes',
    'jantes-alu',
    'map-picker',
    'radar',
    'regulateur-distance',
    'regulateur-vitesse',
    'sept-places',
    'sieges-chauffants',
    'sieges-electriques',
    'son-plus',
    'toit-ouvrant',
    'toit-pano',
    'xenon-led',
    'xenon-led',
  ];
  switch (type) {
    case 'clim':
      return 'climatisation';
    case 'sieges_chauffants':
      return 'sieges-chauffants';
    case 'tel_bluetooth':
      return 'bluetooth';
  }
  const typeWithDash = type.replace('_', '-');
  if (existentType.includes(typeWithDash)) {
    return typeWithDash;
  }

  throw Error(`Icon type ${type} not exist`);
};
