import React from 'react';

import styles from './Tooltip.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

type TooltipProps = {
  text?: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  className?: string;
  mode?: 'dark' | 'light';
};

const Tooltip: React.FC<TooltipProps> = ({
  text,
  placement = 'top',
  className,
  mode = 'dark',
  children,
}) => {
  return (
    <>
      <div
        className={mergeClassNames([
          styles.tooltip,
          mode === 'light' && styles.tooltipLight,
          className,
        ])}
      >
        {text ?? children}
        <span
          className={mergeClassNames([
            styles.triangle,
            styles[`triangle-${placement}`],
          ])}
        />
      </div>
    </>
  );
};

export default Tooltip;
