import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import type { Vehicle } from 'src/models';

import { Icon } from 'src/components/shared';

import { VehicleCreditModal } from './vehcileCreditModal/VehcileCreditModal';
import style from './VehicleFunding.module.scss';
type VehicleFundingType = {
  vehicle: Vehicle;
  withoutText?: boolean;
};

const VehicleFunding: FunctionComponent<VehicleFundingType> = ({
  vehicle,
  withoutText,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  if (!vehicle.fundingEligible) {
    return <></>;
  }
  return (
    <>
      <span className={style.textFunding}>ou</span>{' '}
      <span className={style.fundingText}>
        {!withoutText && <p>à partir de*</p>}
        <div>
          <strong>
            {Math.round(vehicle.fundingInstallment).toLocaleString()}€
          </strong>{' '}
          /mois{withoutText && '*'}
          <Icon
            name="info-presta"
            width={16}
            height={16}
            className={style.fundingTextIcon}
            onClick={(e) => {
              e.preventDefault();
              setIsModalVisible(true);
            }}
          />
        </div>
      </span>
      <VehicleCreditModal
        isVisible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        vehicle={vehicle}
        duration={vehicle.fundingDuration}
        fundingIntake={vehicle.fundingIntake}
        installment={vehicle.fundingInstallment}
        rate={vehicle.fundingRate}
        sphinxRate={vehicle.fundingFees}
        taeg={vehicle.fundingTaeg}
      />
    </>
  );
};

export default VehicleFunding;
