/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, cloneElement, useRef, useLayoutEffect } from 'react';

import styles from './OverlayTrigger.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

type OverlayTriggerProps = {
  overlay: React.ReactNode;
  children: React.ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  className?: string;
  isVerticalyCentered?: boolean;
};

const OverlayTrigger: React.FC<OverlayTriggerProps> = ({
  overlay,
  children,
  placement = 'top',
  className,
  isVerticalyCentered = false,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const overlayRef = useRef<HTMLDivElement>(null);
  const [overlayPosition, setOverlayPosition] = useState({ top: 0 });

  const handleMouseOver = () => {
    setShowOverlay(true);
  };

  const onMouseOut = () => {
    setShowOverlay(false);
  };

  useLayoutEffect(() => {
    if (isVerticalyCentered && overlayRef.current) {
      const tooltipRect = overlayRef.current.firstChild as HTMLDivElement;
      const top = -(tooltipRect?.getBoundingClientRect().height / 2 - 10);
      setOverlayPosition({ top });
    }
  }, [isVerticalyCentered, showOverlay]);

  const triggerProps: any = {};
  triggerProps.onMouseOver = handleMouseOver;
  triggerProps.onMouseOut = onMouseOut;

  return (
    <>
      <div className={mergeClassNames([styles.overlayContainer, className])}>
        {cloneElement(children as any, triggerProps)}

        {showOverlay && (
          <div
            ref={overlayRef}
            className={styles[`overlay-${placement}`]}
            style={{
              position: 'absolute',
              ...(isVerticalyCentered && overlayPosition),
            }}
          >
            {overlay}
          </div>
        )}
      </div>
    </>
  );
};

export default OverlayTrigger;
