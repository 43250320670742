import { useRouter } from 'next/router';
import type { FunctionComponent } from 'react';
import React from 'react';
import TagManager from 'react-gtm-module';

import { Icon, Image, Link, Price, Svg, Title3 } from 'src/components/shared';
import { Col, Row } from 'src/components/ui';
import OverlayTrigger from 'src/components/ui/OverlayTrigger';
import Tooltip from 'src/components/ui/Tooltip';

import VehicleEquipmentIcon from '../../[...slug]/components/VehicleEquipmentIcon/VehicleEquipmentIcon';
import VehicleFunding from '../vehicleFunding/VehicleFunding';
import style from './VehicleVignette.module.scss';

import type { Vehicle } from 'src/models/vehicle';

import { mergeClassNames, usePageType } from 'src/utils/ReactUtils';
import type { SeoTextTag } from 'src/utils/SeoUtils';
import {
  getVehiclePrice,
  vehicleIsCertified,
  vehicleIsExclusive,
  vehicleIsPepite,
} from 'src/utils/VehicleUtils';

export type VehicleProps = {
  vehicle: Vehicle;
  small?: boolean;
  className?: string;
  idBaseNetwork?: string;
  titleLevel?: SeoTextTag;
};

const VehicleVignette: FunctionComponent<VehicleProps> = ({
  vehicle,
  small,
  className,
  idBaseNetwork,
  titleLevel = 'h4',
}) => {
  const TitleTag: keyof JSX.IntrinsicElements = titleLevel;

  const pageType = usePageType();
  const router = useRouter();
  const { id_network_base } = router.query;
  const idGarage =
    idBaseNetwork ??
    (typeof id_network_base === 'string' ? id_network_base : undefined);
  const address = () => {
    if (!vehicle.garage) {
      return 'Livraison partout en france';
    }
    const MAX_LENGTH = 25;
    const departmentCode = `(${vehicle.garage?.zipCode.slice(0, 2)})`;
    if (vehicle.garage?.name?.length <= MAX_LENGTH) {
      return `${vehicle.garage?.name} ${departmentCode}`;
    }
    return (
      <OverlayTrigger
        placement="right"
        className={style.vehicleOverlayTrigger}
        overlay={
          <Tooltip
            placement="right"
            text={`${vehicle.garage?.name} ${departmentCode}`}
            className={style.vehicleTooltip}
          />
        }
      >
        <span className={style.vehicleFicheInfoListValueInfoIcon}>
          {`${vehicle.garage?.name.slice(0, MAX_LENGTH)}... ${departmentCode}`}
        </span>
      </OverlayTrigger>
    );
  };

  const shouldPerformSEOTrick = idGarage;

  return (
    <article
      className={mergeClassNames([
        style.vehicleVignette,
        className,
        small && style.isSmall,
      ])}
      data-testid="vehicle_vignette"
    >
      <Link
        href={vehicle.vehicleDetailsUrl}
        className={style.link}
        query={
          idGarage && !shouldPerformSEOTrick
            ? { id_network_base: idGarage }
            : undefined
        }
        onClick={(e) => {
          if (shouldPerformSEOTrick) {
            e.preventDefault();

            window.location.href =
              vehicle.vehicleDetailsUrl +
              (vehicle.vehicleDetailsUrl.includes('?') ? '&' : '?') +
              'id_network_base=' +
              idGarage;
          }

          TagManager.dataLayer({
            dataLayer: {
              event: 'eventGA',
              eventCategory: 'liste VO',
              eventAction: 'clic annonce',
              eventLabel: `${vehicle.brandLabel} ${vehicle.versionLabel}`,
              priceVO: vehicle.price,
              enseigneGarage: vehicle.garage?.name ?? 'Cargroup',
              pageType,
            },
          });
        }}
        data-testid={`vehicle_vignette_${vehicle.reference}`}
      >
        <div
          className={mergeClassNames([
            style.imgContainer,
            small && style.smallImgContainer,
          ])}
        >
          {vehicleIsExclusive(vehicle) && (
            <span className={style.badgeExclu} data-testid="badge_exclusives">
              {vehicleIsExclusive(vehicle)?.label}
            </span>
          )}
          <div className={style.imgVehicle}>
            <Image
              src={vehicle.photos[0]}
              alt={`${vehicle.brandLabel} ${vehicle.versionLabel} ${vehicle.finition} occasion`}
              layout="fill"
              className={style.imgVehicle}
            />
          </div>

          <span className={style.nbImgs}>
            <span className="sr-only"> Nombre de photos :</span>
            <Icon
              width={16}
              height={16}
              name="camera"
              className={style.nbImgsIcon}
            />
            <span data-testid={'photos_count'}> {vehicle.photos?.length} </span>
          </span>
          {vehicle.priceReduced && (
            <span className={style.badgeText}>prix en baisse</span>
          )}
        </div>
        <div className={style.content}>
          <header className={style.header}>
            <Title3 className={style.title} data-testid={`title`}>
              <TitleTag>
                {vehicle.brandLabel + ' ' + vehicle.versionLabel}
                <small className={style.version}> {vehicle.finition} </small>
              </TitleTag>
            </Title3>
          </header>
          <div className={style.infoList}>
            <ul>
              <li data-testid="vehicle_energy_label">{vehicle.energyLabel}</li>
              <li data-testid="vehicle_mileage">{vehicle.mileage}&nbsp;km</li>
              <li data-testid="vehicle_year">
                {new Date(vehicle.registrationDate).getFullYear()}
              </li>
              <li>{vehicle.gearboxTypeLabel}</li>
            </ul>
          </div>
          {small && (
            <div>
              <strong className={style.price}>
                <Price
                  price={getVehiclePrice(vehicle).toLocaleString('fr-FR')}
                  shouldAddExponent={false}
                  data-testid="vehicle_price"
                />
                €<sup className={style.ttc}>TTC</sup>
              </strong>
              <VehicleFunding vehicle={vehicle} withoutText />
            </div>
          )}

          {!small && (
            <>
              <div className={style.equipmentsList}>
                <ul data-testid="vehicle_highlighted_equipment">
                  {vehicle.highlightedEquipments.map((equipment, index) => (
                    <li key={index} data-testid={equipment.key}>
                      <span className={style.equipment} aria-label="" title="">
                        <VehicleEquipmentIcon
                          equipmentKey={equipment.key}
                          width={16}
                          height={16}
                        />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <Row className={`price ${style.priceContainer}`}>
                <Col md="6">
                  <span className={style.priceLabel}>Prix</span>
                  <strong className={style.price}>
                    <Price
                      price={getVehiclePrice(vehicle).toLocaleString('fr-FR')}
                      shouldAddExponent={false}
                      data-testid="vehicle_price"
                    />
                    €<sup className={style.ttc}>TTC</sup>
                  </strong>

                  {vehicle.priceReduced ? (
                    <s className={style.vehicleFichePriceOriginal}>
                      <Price
                        price={vehicle.price.toLocaleString('fr-FR')}
                        shouldAddExponent={false}
                        data-testid="vehicle_price_reduced"
                      />
                      €
                    </s>
                  ) : null}
                </Col>
                <Col md="6" className={style.fundingVehicle}>
                  <VehicleFunding vehicle={vehicle} />
                </Col>
              </Row>
              <div
                className={mergeClassNames([
                  style.badgeItemContainer,
                  vehicleIsPepite(vehicle) || vehicleIsCertified(vehicle)
                    ? style.badgeItemHasContent
                    : null,
                ])}
              >
                {vehicleIsPepite(vehicle) && (
                  <div
                    className={style.badgeItem}
                    data-testid="badge_ad_occasion_pepite"
                  >
                    <Svg
                      src={`/icons/pages/annonces-vehicules/ad_occasion_pepite.svg`}
                      height={28}
                      width={28}
                    />
                    <p className={style.badgeItemText}>Pépite</p>
                  </div>
                )}
                {vehicleIsCertified(vehicle) && (
                  <div
                    className={style.badgeItem}
                    data-testid="badge_ad_occasion_certifiee"
                  >
                    <Svg
                      src={`/icons/pages/annonces-vehicules/ad_occasion_certifiee.svg`}
                      height={28}
                      width={34}
                    />
                    <p className={style.badgeItemText}>
                      sûr et certifié{' '}
                      {!vehicleIsPepite(vehicle) ? 'par le réseau AD' : null}
                    </p>
                  </div>
                )}
              </div>
              <div className={style.location} data-testid="info_location">
                <Icon
                  name="map-picker"
                  className={style.svgIcon}
                  width={12}
                  height={12}
                />
                {address()}
              </div>
            </>
          )}
        </div>
      </Link>
    </article>
  );
};

export default VehicleVignette;
