import type { FunctionComponent } from 'react';
import React from 'react';
import type { Vehicle } from 'src/models';

import { Icon, Title3 } from 'src/components/shared';
import Modal from 'src/components/ui/Modal/Modal';

import style from './VehicleCreditModal.module.scss';

import { getVehiclePrice, vehicleIsExclusive } from 'src/utils/VehicleUtils';

type VehicleCreditModalType = {
  isVisible: boolean;
  closeModal: () => void;
  vehicle: Vehicle;
  duration: number;
  fundingIntake: number;
  installment: number;
  rate: number;
  taeg: number;
  sphinxRate: number;
};

export const VehicleCreditModal: FunctionComponent<VehicleCreditModalType> = ({
  isVisible,
  closeModal,
  vehicle,
  duration,
  fundingIntake,
  installment,
  rate,
  taeg,
  sphinxRate,
}) => {
  const price = getVehiclePrice(vehicle);
  const assurance = 2.79;
  return (
    <>
      <Modal isOpen={isVisible} onClose={closeModal}>
        <div className={style.modalContent}>
          <Icon
            name="close"
            width={18}
            height={18}
            className={style.closeModal}
            onClick={closeModal}
          />
          <div>
            {vehicleIsExclusive(vehicle) ? (
              <p>
                Les frais de 250€ incluent la livraison de votre véhicule
                partout en France chez le réparateur « AD Occasion » agréé
                centre livreur le plus proche de chez vous. Ce forfait comprend
                aussi les frais administratif en dehors du coût de la carte
                grise restant à votre charge.
              </p>
            ) : (
              <>
                <Title3>
                  <h3>
                    Un crédit vous engage et doit être remboursé. Vérifiez vos
                    capacités de remboursement avant de vous engager.
                  </h3>
                </Title3>
                <p>
                  Aucun versement sous quelque forme que ce soit ne peut être
                  exigé
                  {
                    "d'un particulier avant l'obtention d'un ou plusieurs prêts d'argent."
                  }
                </p>
                <p>
                  Exemple de financement en crédit sur{' '}
                  <strong>{duration} mois</strong> pour cette{' '}
                  <strong>
                    {vehicle.brandLabel} {vehicle.versionLabel}
                  </strong>{' '}
                  vendue au prix de <strong>{price}€ TTC</strong> avec un apport
                  de <strong>{fundingIntake} €</strong> :
                </p>
                <p>
                  soit un montant financé de{' '}
                  <strong>{price - fundingIntake} €</strong>, remboursable en{' '}
                  <strong>{duration} mensualités </strong> de{' '}
                  <strong>{installment}</strong> €.
                </p>
                <p>
                  Montant total dû par l’emprunteur hors assurance(s) et/ou
                  prestation(s) facultative(s):{' '}
                  <strong>{installment * duration}</strong>€
                </p>
                <p>
                  Taux débiteur fixe de <strong>{rate}%</strong>, TAEG fixe de{' '}
                  <strong>{taeg} %</strong>.
                </p>
                <p>
                  Frais de dossier <strong>{sphinxRate}€</strong>-Assurance
                  facultative décès/perte totale et irréversible d’autonomie de{' '}
                  {assurance}€ par mois (qui s’ajoute à l’échéance de votre
                  remboursement de crédit). Taux annuel effectif de l’assurance
                  1,30% –Coût total sur{' '}
                  <strong>
                    {duration} mois : {Math.floor(assurance * duration)}€.
                  </strong>
                </p>
                <p>
                  Offre réservée aux particuliers majeurs résidant en France,
                  sous
                  {
                    "réserve d'acceptation d'un établissement de financement partenaire"
                  }
                  de Sphinx.
                </p>
                <p>
                  Vous disposez d’un délai de rétractation de 14 jours
                  calendaires à compter de la signature de l’offre de crédit
                  –Sans ouverture de compte.
                </p>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
