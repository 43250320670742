import type { FunctionComponent } from 'react';
import React from 'react';

import { Svg } from '../../../../../components/shared';
import { getEquipmentIconName } from '../vehicleUtils';

type VehicleEquipmentIconProps = {
  equipmentKey: ReturnType<typeof getEquipmentIconName>;
  height?: number;
  width?: number;
};

const VehicleEquipmentIcon: FunctionComponent<VehicleEquipmentIconProps> = ({
  equipmentKey,
  height = 20,
  width = 20,
}) => {
  return (
    <Svg
      src={`/icons/pages/vehicule-details/${getEquipmentIconName(
        equipmentKey,
      )}.svg`}
      height={height}
      width={width}
    />
  );
};

export default VehicleEquipmentIcon;
